import React from 'react'
import { useStaticQuery, graphql, Link as GLink, navigate } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button, Message, Link } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack effectProps={{effect:"fadeInLeft"}}>
        <Main>
          <PageTitle
            header='About Me'
            subheader='Making my whole life proves that God is good.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button onClick={() => navigate('/contact')} sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  Hi, mình là Kha, mình là người Tin lành, và hiện công việc
                  chính của mình là một Software engineer. Câu chuyện cuộc đời
                  mình có lẽ được bắt đầu kể từ khi mình nhận biết được Thượng
                  đế là ai, mình là ai và sự tồn tại của mình THỰC SỰ có một mục
                  đích trên đất này.
                </Text>
                <Text variant='p'>
                  Trước đó thì mình vẫn có cuộc sống bình thường, gặp thử thách
                  và khó khăn như bao người, nhưng thực sự mình sống để hoàn
                  thành câu chuyện mà người khác muốn viết lên. Nếu bạn từng suy
                  nghĩ về những câu hỏi kiểu như: Mình từ đâu đến, mình sống để
                  làm gì, mình sẽ đi về đâu sau khi qua đời? thì có lẽ bạn là
                  người rất nghiêm túc cho những việc mình đang làm, những mối
                  quan hệ, và thời gian mà bạn đang có, umm… đó cũng chính là
                  mình loay hoay mãi với những câu hỏi này cách đây vài năm.
                </Text>
                <Text variant='p'>
                  Và rồi mình đặt niềm tin vào God, những câu hỏi ấy được giải
                  quyết cách lạ thường, cuộc sống mình bước sang một trang mới
                  với nhiều niềm vui và hy vọng, sự bình an ngập tràn trong tâm
                  hồn, đó là lúc mình cảm nhận được mình đang SỐNG :)
                </Text>
                <Text variant='p'>
                  Kể từ đó đến nay cũng gần 4 năm rồi, cuộc sống có nhiều thay
                  đổi theo hướng tích cực, mình làm phần mềm, chơi piano, chơi
                  guitar, và sinh hoạt cùng với cộng động anh em rất đáng yêu
                  tại một nhà thờ nhỏ ở quận Gò vấp hehe.
                </Text>
                <Text variant='p'>
                  Câu chuyện của mình sẽ luôn được tiếp diễn theo cái cách mà
                  Thượng đế mong muốn, và đây cũng là nơi mình sẽ tiếp tục chia
                  sẻ và lan toả điều đó, mình rất vui nếu được kết nối với các
                  bạn thông qua website này :D
                </Text>
                <Text variant='p'>
                  Nhân tiện, đây là facebook của Jerusalem Youth mà mình đang sinh hoạt,
                  đừng ngần ngại nhắn tin tham gia cùng với chúng mình nếu bạn
                  muốn, đây là môi trường của nhiều bạn trẻ rất năng động và hài
                  hước đó nhé:
                  <Message sx={{mt: '8px'}}>
                    <Link
                      target="_blank"
                      variant='mute'
                      as={GLink}
                      to='https://www.facebook.com/jerusalemyouth20'
                    >
                      https://www.facebook.com/jerusalemyouth20
                    </Link>
                  </Message>
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Software development'>
                <Card variant='paper'>
                  Mình là 1 fullstack developer có 3 năm kinh nghiệm trong lĩnh vực phần mềm, đa số những gì mình biết đều là nhờ tự học trên Udemy :)
                  :D
                </Card>
              </Section>
              <Divider />
              <Section title='Guitar'>
                <Card variant='paper'>
                  Thấm thoát 4 năm trôi qua kể từ ngày mình bắt đầu đi học
                  Guitar. Phải nói đây là một công cụ rất hữu ích để có thể cân
                  bằng cuộc sống và kết nối làm quen bạn mới kkkk. Hiện tại mình
                  đang sử dụng kỹ năng mà Chúa ban này để phục vụ tại Hội thánh, trong vai trò là một Worship Leader.
                </Card>
              </Section>
              <Divider />
              <Section title='Piano'>
                <Card variant='paper'>
                  Mình chơi piano cũng được 4 năm, đa số là tự học và được anh
                  em trong nhà thờ chỉ dẫn. Hiện tại đây là vị trí mình chơi
                  chính trong band nhạc tại Hội thánh Jerusalem.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
